<template>
  <NuxtI18nLink
    :to="notAvailableInRegion ? NOT_AVAILABLE_LINK : realLink"
    :target="notAvailableInRegion ? '_blank' : '_self'"
    class="game-card-wrapper"
    data-t="game-card"
    :data-game-id="game.gameId"
    :title="game.name"
    :class="cardClasses"
  >
    <div v-if="labels.length && !notAvailableInRegion" class="labels">
      <StLabel
        v-for="label in labels"
        :key="label.name"
        :label="label.name"
        :bg-color="label.color"
        data-t="game-label-m3a3"
      />
    </div>
    <article class="game-card">
      <figure class="img-wrapper">
        <div class="img" data-t="game-image-6jvr">
          <img :src="game.image" alt="" width="216" loading="lazy" />
          <div class="hover-card">
            <div class="top-info">
              <FavoriteButton
                v-if="isAuthenticated"
                :id="game.gameId"
                data-t="game-add-to-favorite-i8la"
              />
              <StTooltip
                v-if="tooltipLabels.length"
                class="game-tooltip"
                placement="bottom-end"
                disable-teleport
                data-t="game-tooltip-jmgx"
                :styles="'border-top-right-radius: 4px'"
              >
                <template #activator>
                  <div class="info-icon-wrapper">
                    <StIcon class="icon" name="info" size="16" />
                  </div>
                </template>
                <div class="tags-wrapper" data-t="tags-wrapper-cpu1">
                  <NuxtI18nLink
                    v-for="label in tooltipLabels"
                    :key="label.code"
                    :to="label.link"
                    class="tooltip-tag"
                    data-t="tooltip-tag-rj83"
                  >
                    {{ label.name }}
                  </NuxtI18nLink>
                </div>
              </StTooltip>
            </div>
            <div class="buttons">
              <StButton
                :to="realLink"
                class="play-button"
                :class="{ 'play-button-centered': !game.hasDemoMode }"
                data-t="play-button"
              >
                <StIcon class="play-icon" name="play-solid" size="25" />
              </StButton>
              <StButton
                v-if="game.hasDemoMode"
                :label="t('gameCard.demoMode')"
                :to="demoLink"
                class="gray-button"
                size="xs"
                type="gray"
                data-t="demo-button"
              />
            </div>
          </div>
          <div v-if="notAvailableInRegion" class="img-content">
            {{ t('casino.notAvailableInRegion') }}
            <p class="how-change">
              {{ t('casino.howChangeRegion') }}
            </p>
          </div>
          <GameCardAnimation
            :image="game.image"
            :game-id="game.gameId"
            :is-animated="isAnimated"
          />
        </div>
        <figcaption class="info-wrapper">
          <div class="text-wrapper">
            <h3 class="card-header" data-t="game-name" :title="game.name">
              {{ game.name }}
            </h3>
            <p class="card-caption" data-t="game-provider">
              {{ game.provider }}
            </p>
          </div>
          <div v-if="showPlayers" class="players-info">
            <span class="players-count">
              <StLiveIndicator :size="16" color="orange" />
              <span class="count">{{ game.playersCount }}</span>
            </span>
            <span class="players">{{ t('gameCard.plays') }}</span>
          </div>
        </figcaption>
        <div v-if="isLounchingGame" class="loader">
          <StSpinner size="20" />
        </div>
      </figure>
    </article>
  </NuxtI18nLink>
</template>

<script lang="ts" setup>
import { useUserStore } from '@st/user/stores/useUserStore'
import type { Game } from '../../types'
import useGameCard from './useGameCard'
import FavoriteButton from './parts/FavoriteButton.vue'
import GameCardAnimation from './GameCardAnimation.vue'
import { useGameOpenAnimation } from '../../composables/useGameOpenAnimation'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    game: Game
    showPlayerCount?: boolean
  }>(),
  {
    showPlayerCount: true,
  },
)

const { isAuthenticated } = storeToRefs(useUserStore())

const { game, showPlayerCount } = toRefs(props)
const { labels, tooltipLabels, showPlayers, notAvailableInRegion } =
  useGameCard(game, showPlayerCount)

const NOT_AVAILABLE_LINK = 'https://help.betone.com/articles/9516574'
const demoLink = computed(() => `/casino/games/${game.value.gameId}?mode=demo`)
const realLink = computed(() => `/casino/games/${game.value.gameId}?mode=real`)

const { isLounchingGame, isAnimated } = useGameOpenAnimation(
  toRef(() => game.value.gameId),
)

const cardClasses = computed(() => ({
  loading: isLounchingGame.value,
  animated: isAnimated.value,
  'not-available': notAvailableInRegion.value,
}))
</script>

<style lang="css" scoped>
.labels {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: -4px;
  transform: translateX(0);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: flex-start;

  opacity: 1;

  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}

.img-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-0);
  align-items: center;
  align-self: stretch;

  margin: 0;

  filter: blur(0);
  border-radius: var(--border-radius-150);

  transition: filter 0.2s ease-in-out;
}

.img {
  position: relative;
  z-index: 1;
  transform: translateY(0);

  width: 100%;
  height: 146px;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);

  transition: transform 0.2s ease-in-out;

  img {
    position: absolute;
    z-index: -1;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: var(--border-radius-150);
  }
}

.info-wrapper {
  display: flex;
  gap: var(--spacing-075);
  align-items: flex-start;
  align-self: stretch;

  padding: var(--spacing-075) var(--spacing-050) var(--spacing-0)
    var(--spacing-050);
}

.hover-card {
  pointer-events: none;

  position: absolute;
  z-index: 2;
  inset: 0;

  display: flex;
  flex-direction: column;

  opacity: 0;
  background: rgb(0 0 0 / 68%);
  border-radius: var(--border-radius-150);

  transition: opacity 0.2s ease-in-out;
}

.play-button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 54px;
  height: 54px;

  background: var(--text-link);
  border-radius: var(--border-radius-full) !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);

  .st-icon {
    width: 25px;
    height: 25px;
  }
}

.play-button-centered {
  margin-top: var(--spacing-200);
}

.text-wrapper {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;

  min-width: 0;
}

.card-header {
  overflow: hidden;

  max-width: 100%;
  margin: 0;

  font: var(--desktop-text-md-semibold);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-caption {
  overflow: hidden;

  max-width: 100%;
  margin: 0;

  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.players-info {
  display: flex;
  flex-direction: column;
  gap: 1px; /* stylelint-disable-line */
  align-items: flex-end;
  justify-content: center;

  width: var(--spacing-600);

  padding-top: 3px; /* stylelint-disable-line */
}

.players-count {
  display: flex;
  align-items: center;
  font: var(--desktop-text-xs-medium);
}

.count {
  font: var(--desktop-text-sm-medium);
}

.players {
  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
}

.top-info {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-050);
}

.info-icon {
  background-color: var(--icon-secondary);
  transition: background-color 0.2s ease-in-out;
}

.gray-button {
  margin: var(--spacing-200) auto 0;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.tags-wrapper {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-050);

  max-width: 200px;
  max-height: 140px;
  padding: var(--spacing-100);
}

.tooltip-tag {
  padding: var(--spacing-025) var(--spacing-100);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
  text-decoration: none;

  background-color: var(--background-primary);
  border-radius: var(--spacing-125);

  &:hover {
    color: var(--text-link);
  }
}

.img-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: var(--spacing-300);

  font: var(--desktop-text-sm-semibold);
  text-align: center;

  background-color: #000000a3; /* stylelint-disable-line */
  border-radius: var(--border-radius-150);
}

.how-change {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--button-primary-default);
}

.game-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
}

.info-icon-wrapper {
  cursor: help;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  padding: var(--spacing-050);

  background-color: rgb(0 0 0 / 48%);
  backdrop-filter: blur(4px);
  border-radius: var(--border-radius-full);

  .icon {
    background-color: var(--icon-secondary);
  }

  &:hover .icon {
    background-color: var(--icon-primary);
  }
}

.game-card {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  align-items: flex-start;

  width: 100%;
  min-width: 216px;
  height: auto;

  border-radius: var(--border-radius-150);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);

  &:hover {
    .img {
      transform: translateY(-4px);
    }

    .hover-card {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.game-card-wrapper {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: block;

  padding-top: var(--spacing-050);

  color: var(--text-primary);
  text-decoration: none;

  transition: transform 0.2s ease-in-out;

  /* shadow */
  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 66px;
    left: 50%;
    transform: translate(-50%);

    width: 180px;
    height: 70px;

    opacity: 0;
    filter: blur(25px);
    border-radius: var(--border-radius-150);

    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 0.48;
    }

    .labels {
      transform: translateX(8px);
      opacity: 0;
    }
  }

  &.not-available {
    &::after {
      content: '';

      position: absolute;
      z-index: 1;
      inset: 0;

      border-radius: var(--border-radius-150);
    }
  }

  &.loading {
    .img img {
      opacity: 0.66;
    }

    .hover-card {
      opacity: 0;
    }
  }

  &.animated {
    .labels {
      opacity: 0;
    }
  }
}

.loader {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
}
</style>
